<template>
    <section class="component-box">
        <div class="flex items-center justify-between space-x-2 mb-4">
            <div>
                <p class="font-semibold">
                    {{ $t('common.autobot') }}
                </p>
            </div>

            <div>
                <el-button
                    :disabled="!current_package"
                    type="primary"
                    size="small"
                    icon="el-icon-add"
                    @click="show_dialog.add_tiktok_account = true"
                >
                    {{ $t('page.autobot.add_autobot') }}
                </el-button>
            </div>
        </div>

        <!-- <el-alert
            :title="$t('page.tiktok_identity.info')"
            type="success"
            class="mb-2"
            :closable="false"
        >
        </el-alert> -->

        <!--        <pre>{{ request_list }}</pre>-->
        <el-table v-loading="m__loading" :data="request_list">
            <el-table-column label="#" type="index" width="60" />

            <el-table-column width="240" :label="'ID'">
                <template slot-scope="scope">
                    {{ scope.row._id }}
                </template>
            </el-table-column>
            <el-table-column width="240" :label="$t('common.name')">
                <template slot-scope="scope">
                    {{ scope.row.name }}
                </template>
            </el-table-column>
            <el-table-column width="240" :label="$t('common.description')">
                <template slot-scope="scope">
                    {{ scope.row.description }}
                </template>
            </el-table-column>
            <el-table-column width="180" :label="$t('common.status')">
                <template slot-scope="scope">
                    <el-switch
                        :value="scope.row.active === 'true'"
                        @change="handleToggleChangeStatusAutobot(scope.row._id)"
                    ></el-switch>
                </template>
            </el-table-column>
            <el-table-column width="240" :label="$t('common.created_at')">
                <template slot-scope="scope">
                    {{
                        scope.row.created_at
                            | f__format_moment($t('common.format_date'))
                    }}
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                min-width="240"
                :label="$t('common.option')"
            >
                <template slot-scope="scope">
                    <el-button
                        type="primary"
                        size="small"
                        @click="configAutobot(scope.row)"
                    >
                        Cấu hình
                    </el-button>
                    <el-button
                        type="danger"
                        size="small"
                        @click="deleteRequestIdentity(scope.row._id)"
                    >
                        {{ $t('button.delete') }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="flex items-center mt-4 justify-end">
            <el-switch
                v-model="switch_auto_refresh"
                @change="handleToggleAutoRefresh"
            ></el-switch>
            <p class="text-xs mx-1">
                {{ $t('page.campaign.auto_refresh') }}
            </p>
            <el-tooltip
                effect="dark"
                :content="$t('page.campaign.desc_auto_refresh')"
                placement="top"
            >
                <i class="el-icon-info text-desc-text"></i>
            </el-tooltip>
        </div>

        <el-dialog
            v-loading="loading.add_tiktok_account"
            :visible.sync="show_dialog.add_tiktok_account"
            :append-to-body="true"
            :close-on-click-modal="true"
            width="520px"
        >
            <div slot="title">
                <div class="text-left truncate w-4/5 text-base font-semibold">
                    {{ $t('page.autobot.dialog_title') }}
                </div>
            </div>

            <div>
                <div>
                    <!-- <el-alert
                        :title="$t('page.tiktok_identity.how_find_it_message')"
                        type="success"
                        :closable="false"
                        class="mb-2"
                    >
                    </el-alert> -->
                    <span>{{ $t('common.name') }}</span>
                    <el-input
                        v-model="form_add_autobot.name"
                        size="small"
                        :placeholder="$t('page.autobot.enter_autobot_name')"
                    ></el-input>

                    <p class="mt-3">{{ $t('common.description') }}</p>
                    <el-input
                        v-model="form_add_autobot.description"
                        type="textarea"
                        :rows="2"
                        placeholder="Nhập mô tả ngắn"
                    >
                    </el-input>
                </div>
            </div>

            <template slot="footer">
                <div class="flex items-center justify-end mt-4 justify-between">
                    <div>
                        <!--                        <el-button-->
                        <!--                            type="primary"-->
                        <!--                            size="small"-->
                        <!--                            @click="show_dialog.get_qr_code = true"-->
                        <!--                        >-->
                        <!--                            {{ $t('page.tiktok_identity.get_qr_code') }}-->
                        <!--                        </el-button>-->
                    </div>
                    <div>
                        <el-button
                            size="small"
                            @click="show_dialog.add_tiktok_account = false"
                        >
                            {{ $t('button.close') }}
                        </el-button>

                        <el-button
                            type="primary"
                            size="small"
                            icon="el-icon-check"
                            @click="requestAnIdentity"
                        >
                            {{ $t('button.confirm') }}
                        </el-button>
                    </div>
                </div>
            </template>
        </el-dialog>

        <el-dialog
            v-loading="loading.get_qr_code"
            :visible.sync="show_dialog.get_qr_code"
            :append-to-body="true"
            :close-on-click-modal="true"
            width="600px"
        >
            <div slot="title">
                <div class="text-left truncate w-4/5 text-base font-semibold">
                    {{ $t('page.tiktok_identity.dialog_title') }}
                </div>
            </div>

            <div>
                <div style="word-break: break-word">
                    {{ $t('page.tiktok_identity.dialog_note') }}
                </div>

                <div class="mt-6">
                    <el-button
                        size="mini"
                        icon="el-icon-copy-document"
                        @click="copyQRCode"
                    >
                        {{ $t('page.tiktok_identity.copy_qr_code') }}
                    </el-button>
                </div>

                <div class="mt-3">
                    <div
                        class="rounded-[8px] py-[22px] px-[32px]"
                        :style="{
                            background:
                                'linear-gradient(35.36deg,#121212 22.27%,#444 99.35%)'
                        }"
                    >
                        <div class="flex">
                            <div>
                                <img :src="getQrCodeURL()" alt="" />
                            </div>
                            <div class="pl-[30px] text-[#fff]">
                                {{
                                    $t('page.tiktok_identity.dialog_content_1')
                                }}
                                <br />
                                <br />
                                {{
                                    $t('page.tiktok_identity.dialog_content_2')
                                }}
                                <br />
                                {{
                                    $t('page.tiktok_identity.dialog_content_3')
                                }}
                                <br />
                                <br />
                                {{
                                    $t('page.tiktok_identity.dialog_content_4')
                                }}
                                <br />
                                {{
                                    $t('page.tiktok_identity.dialog_content_5')
                                }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-6 bg-[f8f8f8] text-[#000] pl-[30px]">
                    <strong>{{ $t('page.tiktok_identity.scan_code') }}</strong>
                    <br />
                    {{ $t('page.tiktok_identity.scan_code_1') }}
                    <br />
                    {{ $t('page.tiktok_identity.scan_code_2') }}
                    <br />
                    {{ $t('page.tiktok_identity.scan_code_3') }}
                    <br />
                    {{ $t('page.tiktok_identity.scan_code_4') }}
                </div>
            </div>

            <template slot="footer">
                <div class="flex items-center justify-end mt-4">
                    <el-button
                        type="primary"
                        size="small"
                        @click="show_dialog.get_qr_code = false"
                    >
                        {{ $t('common.i_got_it') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>

        <ManageAutomaticRuleDrawer
            :visible.sync="is_manage_automatic_rule_drawer_displayed"
            :tiktok_account_id="this.m__selected_tiktok_business.id"
            :autobot="selected_autobot"
            :currency="currency"
            :advertiser_list="m__ads_accounts"
        ></ManageAutomaticRuleDrawer>
    </section>
</template>

<script>
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import {
    commitRequestAnIdentity,
    deleteRequestIdentity,
    getRequestIdentity,
    requestAnIdentity
} from '@/services/atosa-tiktok-ads/identity'
import {
    getAutobots,
    createAutobot,
    updateAutobot,
    deleteAutobot
} from '@/services/atosa-tiktok-ads/autobot'
import ManageAutomaticRuleDrawer from '@/views/autobot/_components/atosa-manage-automatic-rule-drawer'

export default {
    components: {
        ManageAutomaticRuleDrawer
    },

    mixins: [selectedTiktokBusinessMixin],

    data() {
        return {
            polling: null,
            switch_auto_refresh: true,
            show_dialog: {
                add_tiktok_account: false,
                get_qr_code: false
            },
            loading: {
                add_tiktok_account: false,
                get_qr_code: false
            },
            form_add_tiktok_account: {
                tiktok_username: ''
            },
            form_add_autobot: {
                name: '',
                description: ''
            },
            request_list: [],
            is_manage_automatic_rule_drawer_displayed: false,
            selected_autobot: {},
            currency: 'VND'
        }
    },

    computed: {
        current_package() {
            return this.$store.getters.currentPackage.s__current_package
        }
    },

    watch: {
        m__selected_tiktok_business: function () {
            this.getRequestIdentity()
        }
    },

    mounted() {
        this.getRequestIdentity()

        this.handleToggleAutoRefresh()
    },

    beforeDestroy() {
        clearInterval(this.polling)
    },

    methods: {
        handleToggleAutoRefresh() {
            if (this.switch_auto_refresh) {
                this.polling = setInterval(() => {
                    this.getRequestIdentity()
                }, 10e3)
            } else {
                clearInterval(this.polling)
            }
        },

        async copyQRCode() {
            await this.p__CopyText(this.getQrCodeURL())
        },

        getQrCodeURL() {
            return `${process.env.VUE_APP_ATOSA_TIKTOK_ADS_API.replace(
                '/api/v1',
                ''
            )}/qrcode/qrcode.png`
        },

        async getRequestIdentity() {
            if (!this.m__selected_tiktok_business) {
                return
            }

            this.m__loading = true

            try {
                const response = await getAutobots(
                    this.m__selected_tiktok_business.id
                )
                console.log('====================================')
                console.log(response)
                console.log('====================================')
                this.request_list = response.data
                this.m__fetchAdsAccountsByTiktokBusiness()
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async deleteRequestIdentity(autobot_id) {
            if (!this.m__selected_tiktok_business) {
                return
            }

            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                await deleteAutobot(
                    this.m__selected_tiktok_business.id,
                    autobot_id
                )
                await this.getRequestIdentity()
                this.p__showNotify(
                    'success',
                    this.$t('common.delete_data_success')
                )
            } catch (e) {
                console.error(e)
            }
        },

        async requestAnIdentity() {
            if (
                !this.m__selected_tiktok_business ||
                !this.form_add_autobot.name
            ) {
                return
            }

            this.loading.add_tiktok_account = true
            try {
                await createAutobot(
                    this.m__selected_tiktok_business.id,
                    this.form_add_autobot
                )
                await this.getRequestIdentity()
                this.p__showNotify(
                    'success',
                    this.$t('common.request_sent_success')
                )
                this.show_dialog.add_tiktok_account = false
            } catch (e) {
                console.error(e)
            } finally {
                this.loading.add_tiktok_account = false
                this.form_add_autobot.name = ''
                this.form_add_autobot.description = ''
            }
        },

        async commitRequestAnIdentity(data) {
            if (!this.m__selected_tiktok_business) {
                return
            }

            try {
                await this.$confirm(
                    this.$t('page.tiktok_identity.confirm_linked_message'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                await createAutobot(
                    this.m__selected_tiktok_business.id,
                    data.tiktok_username
                )
                await this.getRequestIdentity()
                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )
            } catch (e) {
                console.error(e)
            }
        },

        async UpdateStatusAutobot(data) {
            if (!this.m__selected_tiktok_business) {
                return
            }

            try {
                await this.$confirm(
                    this.$t('page.tiktok_identity.confirm_linked_message'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                await createAutobot(
                    this.m__selected_tiktok_business.id,
                    data.tiktok_username
                )
                await this.getRequestIdentity()
                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )
            } catch (e) {
                console.error(e)
            }
        },

        async handleToggleChangeStatusAutobot(autobot_id) {
            const autobot = this.request_list.find(
                (item) => item._id === autobot_id
            )

            if (!autobot) {
                return
            }

            autobot.active = autobot.active === 'true' ? 'false' : 'true'

            try {
                await updateAutobot(
                    this.m__selected_tiktok_business.id,
                    autobot
                )

                this.p__showNotify(
                    'success',
                    this.$t('page.autobot.message_update_status_success')
                )

                setTimeout(() => {
                    this.getRequestIdentity()
                }, 1500)
            } catch (error) {
                autobot.active = autobot.active === 'true' ? 'false' : 'true'
                console.error(error)

                this.p__showNotify(
                    'error',
                    this.$t('page.autobot.message_update_status_error')
                )
            }
        },

        async configAutobot(autobot) {
            this.is_manage_automatic_rule_drawer_displayed = true
            this.selected_autobot = autobot
        }
    }
}
</script>
