<template>
    <custom-drawer
        v-loading="m__loading"
        size="75%"
        :confirm_to_close="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div class="flex items-center justify-between flex-1 h-full pr-5">
                <div>
                    <p class="font-semibold text-sm">
                        {{ $t('common.autobot') }}
                    </p>
                    <p v-if="autobot" class="text-desc-text text-xs">
                        #{{ autobot._id }}
                    </p>
                </div>
                <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    @click="HandleUpdateAutobot()"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>

        <div class="px-5 py-8">
            <div class="component-box mb-4">
                <div>
                    <label class="component-label">
                        {{ $t('page.campaign.campaign_name') }}
                    </label>
                    <br />
                    <el-input
                        ref="el_input_campaign_name"
                        size="small"
                        class="w-1/2"
                        v-model="autobot.name"
                    ></el-input>
                </div>
            </div>
            <div class="component-box mb-4">
                <label class="component-label">
                    {{ $t('page.autobot.target') }}
                </label>
                <br />
                <el-select
                    v-model="autobot.advertiser_list"
                    value-key="advertiser_id"
                    size="small"
                    filterable
                    :placeholder="$t('input.placeholder.select_ads_account')"
                    class="w-1/2"
                    multiple
                >
                    <el-option-group :label="$t('common.advertiser_account')">
                        <el-option
                            v-for="item in advertiser_list"
                            :key="item.advertiser_id"
                            :label="item.name"
                            :value="item.advertiser_id"
                        >
                            <div class="relative">
                                <div class="flex flex-col py-1">
                                    <span
                                        class="leading-5 text-xs font-semibold"
                                    >
                                        {{ item.name }}
                                    </span>
                                    <div
                                        class="flex items-center space-x-1 leading-3"
                                    >
                                        <i class="el-icon-money"></i>

                                        <span class="text-xs">
                                            {{
                                                p__renderMoneyByCurrency(
                                                    item.balance,
                                                    item.currency
                                                )
                                            }}
                                        </span>
                                    </div>
                                    <div class="flex items-center">
                                        <span class="component-text-desc">
                                            #{{ item.advertiser_id }}
                                        </span>

                                        <div
                                            v-if="item.belong_to_atosa"
                                            class="ml-auto bg-brand-atosa--light util-flex-center px-1 text-white h-5"
                                        >
                                            <span class="text-xxxs uppercase">
                                                atosa
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-option>
                    </el-option-group>
                </el-select>
            </div>

            <div class="component-box">
                <div class="flex items-center justify-between space-x-2 mb-4">
                    <div>
                        <p class="font-semibold">
                            {{ $t('page.autobot.action') }}
                        </p>
                    </div>

                    <div>
                        <el-button
                            type="primary"
                            size="small"
                            icon="el-icon-add"
                            @click="
                                is_create_automatic_rule_drawer_displayed = true
                            "
                        >
                            {{ $t('page.autobot.add_action') }}
                        </el-button>
                    </div>
                </div>

                <el-table
                    v-loading="m__loading"
                    class="campaign-table"
                    :data="m__data_for_pagination"
                >
                    <el-table-column
                        :label="$t('table.header.on_off')"
                        :fixed="true"
                        width="80"
                    >
                        <template #default="scope">
                            <el-switch
                                size="small"
                                :value="scope.row.rule_status === 'OPEN'"
                                @change="handleChangeRuleStatus(scope.row)"
                            >
                            </el-switch>
                        </template>
                    </el-table-column>

                    <el-table-column
                        width="240"
                        :show-overflow-tooltip="true"
                        prop="name"
                        :fixed="true"
                        :label="$t('page.campaign.rule_name')"
                    >
                    </el-table-column>

                    <el-table-column
                        width="200"
                        :show-overflow-tooltip="true"
                        prop="_id"
                        label="ID"
                    >
                    </el-table-column>

                    <el-table-column :label="$t('common.object')" width="240">
                        <template #default="scope">
                            {{
                                renderPreConditionType(
                                    scope.row.apply_objects[0]
                                )
                            }}
                        </template>
                    </el-table-column>

                    <el-table-column
                        :label="$t('common.action')"
                        min-width="240"
                    >
                        <template #default="scope">
                            {{
                                renderActionSubjectType(
                                    scope.row.actions[0].subject_type
                                )
                            }}
                        </template>
                    </el-table-column>

                    <el-table-column
                        width="160"
                        :show-overflow-tooltip="true"
                        prop="created_at"
                        :label="$t('common.created_datetime')"
                    >
                        <template #default="scope">
                            {{
                                scope.row.created_at
                                    | f__format_moment(
                                        $t('common.format_fulldate')
                                    )
                            }}
                        </template>
                    </el-table-column>

                    <el-table-column
                        min-width="200"
                        :show-overflow-tooltip="true"
                        :label="$t('common.option')"
                    >
                        <template #default="scope">
                            <el-button
                                size="small"
                                icon="el-icon-edit"
                                @click="handleEdit(scope.row)"
                            >
                            </el-button>
                            <el-button
                                type="danger"
                                size="small"
                                icon="el-icon-delete"
                                @click="handleRemove(scope.row)"
                            >
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination
                    class="mt-2"
                    :current-page.sync="m__page_info.page"
                    :page-size="m__page_info.page_size"
                    layout="prev, pager, next, total"
                    :total="m__page_info.total_number"
                />
            </div>
        </div>

        <EditAutomaticRuleDrawer
            :visible.sync="is_edit_automatic_rule_drawer_displayed"
            :tiktok_account_id="tiktok_account_id"
            :currency="currency"
            :edited_item="edited_item"
        ></EditAutomaticRuleDrawer>

        <CreateAutomaticRuleDrawer
            :visible.sync="is_create_automatic_rule_drawer_displayed"
            :tiktok_account_id="tiktok_account_id"
            :autobot_id="autobot._id"
            :currency="currency"
            type="ADGROUP"
        ></CreateAutomaticRuleDrawer>
    </custom-drawer>
</template>

<script>
import EditAutomaticRuleDrawer from './edit-automatic-rule-drawer'
import CreateAutomaticRuleDrawer from './atosa-create-automatic-rule-drawer'

import {
    getAutomatedRules,
    updateStatusAutomatedRule
} from '@/services/atosa-tiktok-ads/auto-ads'
import paginationDataMixin from '@/plugins/mixins/pagination-data'
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import { updateAutobot } from '@/services/atosa-tiktok-ads/autobot'

export default {
    components: {
        EditAutomaticRuleDrawer,
        CreateAutomaticRuleDrawer
    },
    mixins: [paginationDataMixin, selectedTiktokBusinessMixin],

    props: [
        'visible',
        'tiktok_account_id',
        'advertiser_id',
        'currency',
        'autobot',
        'advertiser_list'
    ],

    data() {
        return {
            edited_item: null,
            is_edit_automatic_rule_drawer_displayed: false,
            is_create_automatic_rule_drawer_displayed: false,
            m__selected_ads_account_list: [],
            localAutobotState: this.autobot
        }
    },

    computed: {
        visible_in_line: {
            get: function () {
                return this.visible
            },
            set: function (val) {
                this.$emit('update:visible', val)
            }
        }
    },

    watch: {
        visible_in_line() {
            if (this.visible_in_line) {
                this.m__data_for_pagination = []
                this.fetchAutomatedRules()
            }
        },

        'm__page_info.page': function () {
            this.fetchAutomatedRules()
        }
    },

    mounted() {
        this.p__event_bus.$on(
            'eb_reload_list_automated_rules',
            this.fetchAutomatedRules
        )
    },

    destroyed() {
        this.p__event_bus.$off(
            'eb_reload_list_automated_rules',
            this.fetchAutomatedRules
        )
    },

    methods: {
        handleEdit(data) {
            this.edited_item = data
            this.is_edit_automatic_rule_drawer_displayed = true
        },

        async handleRemove(data) {
            try {
                await this.p__showConfirm(
                    'common.note',
                    'common.delete_data_confirm'
                )
            } catch (e) {
                return
            }

            try {
                await updateStatusAutomatedRule(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    operate_type: 'DELETE',
                    rule_ids: [data._id]
                })
                this.fetchAutomatedRules()

                this.p__showNotify(
                    'success',
                    this.$t('common.delete_data_success')
                )
            } catch (e) {
                console.error(e)
            }
        },

        async fetchAutomatedRules() {
            console.log(
                'vào dlsakfjdlaskf',
                this.autobot,
                this.tiktok_account_id
            )

            if (!this.tiktok_account_id || !this.autobot._id) {
                return
            }
            try {
                const response = await getAutomatedRules(
                    this.tiktok_account_id,
                    {
                        autobot_id: this.autobot._id,
                        page: this.m__page_info.page,
                        page_size: this.m__page_info.page_size
                    }
                )

                this.m__data_for_pagination = [...response.data.rules]

                // this.m__page_info = response.data.page_info
            } catch (error) {
                console.error(error)
            }
        },

        async handleChangeRuleStatus(rule) {
            if (!this.tiktok_account_id || !this.advertiser_id) {
                return
            }

            try {
                await updateStatusAutomatedRule(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    operate_type:
                        rule.rule_status === 'OPEN' ? 'TURN_OFF' : 'TURN_ON',
                    rule_ids: [rule._id]
                })

                this.fetchAutomatedRules()

                this.p__showNotify(
                    'success',
                    this.$t('page.campaign.message_update_status_xxx_success', {
                        xxx: this.$t(`common.rule`)
                    })
                )
            } catch (error) {
                console.error(error)

                this.p__showNotify(
                    'error',
                    this.$t('page.campaign.message_update_status_xxx_failed', {
                        xxx: this.$t(`common.rule`)
                    })
                )
            }
        },

        async HandleUpdateAutobot() {
            try {
                console.log(this.autobot, 'sadlasdkj')

                await updateAutobot(this.tiktok_account_id, this.autobot)

                this.p__showNotify(
                    'success',
                    this.$t('page.autobot.message_update_status_success')
                )

                setTimeout(() => {
                    this.getRequestIdentity()
                }, 1500)

                this.visible_in_line = false
            } catch (error) {
                console.error(error)

                this.p__showNotify(
                    'error',
                    this.$t('page.autobot.message_update_status_error')
                )
            }
        },

        renderPreConditionType(apply_object) {
            const { pre_condition_type, dimension, dimension_ids } =
                apply_object

            const xxx = dimension_ids.length

            if (pre_condition_type === 'ALL_ACTIVE_CAMPAIGN') {
                return this.$t('common.all_active_campaign')
            }

            if (pre_condition_type === 'ALL_ACTIVE_AD_GROUP') {
                return this.$t('common.all_active_adgroup')
            }

            if (pre_condition_type === 'ALL_ACTIVE_AD') {
                return this.$t('common.all_active_ad')
            }

            if (pre_condition_type === 'ALL_INACTIVE_CAMPAIGN') {
                return this.$t('common.all_inactive_campaign')
            }

            if (pre_condition_type === 'ALL_INACTIVE_AD_GROUP') {
                return this.$t('common.all_inactive_adgroup')
            }

            if (pre_condition_type === 'ALL_INACTIVE_AD') {
                return this.$t('common.all_inactive_ad')
            }

            if (pre_condition_type === 'SELECTED') {
                if (dimension === 'CAMPAIGN') {
                    return this.$t('page.campaign.xxx_campaign_has_selected', {
                        xxx
                    })
                }
                if (dimension === 'ADGROUP') {
                    return this.$t('page.campaign.xxx_adgroup_has_selected', {
                        xxx
                    })
                }
                if (dimension === 'AD') {
                    return this.$t('page.campaign.xxx_ad_has_selected', {
                        xxx
                    })
                }
            }

            if (pre_condition_type === 'ALL_ACTIVE_AD_GROUP_UNDER_SELECTED') {
                return this.$t(
                    'page.campaign.all_active_adgroup_under_xxx_campaign_has_selected',
                    {
                        xxx
                    }
                )
            }

            if (pre_condition_type === 'ALL_ACTIVE_AD_UNDER_SELECTED') {
                return this.$t(
                    'page.campaign.all_active_ad_under_xxx_campaign_has_selected',
                    {
                        xxx
                    }
                )
            }

            if (pre_condition_type === 'ALL_INACTIVE_AD_GROUP_UNDER_SELECTED') {
                return this.$t(
                    'page.campaign.all_inactive_adgroup_under_xxx_campaign_has_selected',
                    {
                        xxx
                    }
                )
            }

            if (pre_condition_type === 'ALL_INACTIVE_AD_UNDER_SELECTED') {
                return this.$t(
                    'page.campaign.all_inactive_ad_under_xxx_campaign_has_selected',
                    {
                        xxx
                    }
                )
            }

            if (pre_condition_type === 'ALL_ACTIVE_AD_UNDER_SELECTED') {
                return this.$t(
                    'page.campaign.all_active_ad_under_xxx_adgroup_has_selected',
                    {
                        xxx
                    }
                )
            }

            if (pre_condition_type === 'ALL_INACTIVE_AD_UNDER_SELECTED') {
                return this.$t(
                    'page.campaign.all_inactive_ad_under_xxx_adgroup_has_selected',
                    {
                        xxx
                    }
                )
            }
        },

        renderActionSubjectType(subject_type) {
            if (subject_type === 'TURN_ON') {
                return this.$t('common.turn_on')
            }

            if (subject_type === 'TURN_OFF') {
                return this.$t('common.turn_off')
            }

            if (subject_type === 'MESSAGE') {
                return this.$t('page.campaign.notify_me')
            }

            if (subject_type === 'DAILY_BUDGET') {
                return this.$t('page.campaign.adjust_daily_budget')
            }

            if (subject_type === 'LIFETIME_BUDGET') {
                return this.$t('page.campaign.adjust_lifetime_budget')
            }

            if (subject_type === 'BID') {
                return this.$t('page.campaign.adjust_bid')
            }
        }
    }
}
</script>
